<template>
  <div>
    <page-title heading="Happy Hour"
                :permissionAdd="true" show-add-button
                url-add="/erp/baseData/happyHour/add" subheading="Happy Hour" :icon="icon"></page-title>
    <div class="app-main__inner">
      <happy-hour></happy-hour>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import HappyHour from "../../../components/erp/baseData/HappyHour";
import '@mdi/font/css/materialdesignicons.min.css'

export default {
  components: {
    PageTitle,
    HappyHour
  },
  data: () => ({
    icon: 'mdi mdi-mushroom-outline icon-gradient bg-tempting-azure',
  }),
  computed:{
    hybrid(){ return process.env.VUE_APP_HYBRID == 'true' || this.$store.getters["permissions/checkHybridApp"]},
  }
}
</script>